// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-agneya-special-brass-samai-js": () => import("./../../../src/pages/agneya_special/brass_samai.js" /* webpackChunkName: "component---src-pages-agneya-special-brass-samai-js" */),
  "component---src-pages-agneya-special-index-js": () => import("./../../../src/pages/agneya_special/index.js" /* webpackChunkName: "component---src-pages-agneya-special-index-js" */),
  "component---src-pages-agneya-special-laman-diwa-js": () => import("./../../../src/pages/agneya_special/laman_diwa.js" /* webpackChunkName: "component---src-pages-agneya-special-laman-diwa-js" */),
  "component---src-pages-agneya-special-niranjan-js": () => import("./../../../src/pages/agneya_special/niranjan.js" /* webpackChunkName: "component---src-pages-agneya-special-niranjan-js" */),
  "component---src-pages-agneya-special-white-samai-js": () => import("./../../../src/pages/agneya_special/white_samai.js" /* webpackChunkName: "component---src-pages-agneya-special-white-samai-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact_us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-gallery-index-js": () => import("./../../../src/pages/gallery/index.js" /* webpackChunkName: "component---src-pages-gallery-index-js" */),
  "component---src-pages-home-decor-index-js": () => import("./../../../src/pages/home_decor/index.js" /* webpackChunkName: "component---src-pages-home-decor-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-outdoor-utility-index-js": () => import("./../../../src/pages/outdoor_utility/index.js" /* webpackChunkName: "component---src-pages-outdoor-utility-index-js" */)
}

